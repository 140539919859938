import mapiRequest from '~/public/assets/javascripts/mapi';

let profilePromise;

export default function getProfile(forceFetch = false) {
  if (!profilePromise || forceFetch) {
    profilePromise = mapiRequest('user/profile')
      .then(({ profile }) => profile)
      .catch(() => {
        const fpCoookiePresent = document.cookie.includes('fp.auth');
        if (fpCoookiePresent) {
          // if fp cookie is present and profile call fails it is likely due to lingering session cookie, or ianctive state expire it.
          document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
        }
        return null;
      });
  }
  return profilePromise;
}
