import abeagle from './abeagle';
import './vendor/lifecycle';

const featureSlug = 'hp_client_crash_monitor';
document.addEventListener('abeagleEvent', () => {
  const flag = abeagle.getExperimentVariant(featureSlug);
  if (flag === 'on') {
    const isChrome = () => {
      // Get the user agent string
      const { userAgent } = navigator;

      // Check if it contains "Chrome" and does not contain "Edge" or "OPR"
      // "OPR" is for Opera and "Edg" is for Edge.
      const isChromium = userAgent.indexOf('Chrome') > -1
                        && userAgent.indexOf('Edge') === -1
                        && userAgent.indexOf('OPR') === -1
                        && userAgent.indexOf('Edg') === -1;

      // Check if the user agent contains "Chrome" or "CriOS" (for iOS Chrome)
      return isChromium || userAgent.indexOf('CriOS') > -1;
    };

    let shouldPoll = true;

    window.lifecycle.addEventListener('statechange', (event) => {
      if (!['active', 'passive'].includes(event.newState)) {
        shouldPoll = false;
      } else {
        shouldPoll = true;
      }
      // console.log(`Page is ${event.newState} state:', ${shouldPoll}`);
    });

    window.waitForGlobal(() => !!window.HP, () => {
      if (isChrome() && window.Worker && !window.HP?.params?.isPreview && !!window.HP.params.resourceType) {
        console.log('Starting heartbeat worker');
        const worker = new Worker('/heartbeatWorker.js');
        // const url = window.location.href;
        const {
          device, edition, theme, resourceType, isFront, features,
        } = window.HP.params;
        const { adsFree } = features;
        const pageType = isFront ? 'front' : resourceType;
        // Send a message to the worker
        setInterval(() => worker.postMessage({
          type: 'heartbeat',
          time: Date.now(),
          // url,
          shouldPoll,
          device,
          edition,
          theme,
          pageType,
          adsFree,
        }), 1000);
      }
    });
  }
}, { once: true });

window.HP.params.abCrashMonitorFlag.ready = true;
