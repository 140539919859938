/* eslint camelcase: 0 */
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import { createClientEvent } from '@buzzfeed/client-event-tracking';
import {
  addressability,
  content_action,
  impression,
  internal_link,
  external_link,
  pageview,
  ab_test,
  time_spent,
  subbuzz_impression,
  subscription_details,
} from '@buzzfeed/client-event-tracking/events';
import {
  createClientClickHandler,
  createClientImpressionHandler,
  createClientTimeSpentHandler,
} from '@buzzfeed/client-event-tracking/handlers';
import {
  mapAmpDataVars,
  positionInUnit,
  unitDataVars,
  findVidibleCarouselDataVars,
  // findVidiblePlayButtonDataVars,
} from '~/public/assets/javascripts/cetHelper';
import { trackPerformance } from '@buzzfeed/performance';
import { urlQueryToObject } from '@buzzfeed/bf-utils/lib/query-string';
import session from '~/public/assets/javascripts/sessionStorage';
import getProfile from '~/public/assets/javascripts/profile';
import detectAdBlock from '~/public/assets/javascripts/adBlockBlock';

const cetEnabled = window.HUFFPOST.cetEnabled || false;

// Native apps can pass properties that should override any existing values
// for those keys:
const hasCetAppsOverride = window.HUFFPOST.params.isWebview && window.HUFFPOST.params.isEntry;
const isAdsFree = window.HUFFPOST?.params?.features?.adsFree;

const sendClientEvent = cetEnabled ? createClientEvent({ env: window.HUFFPOST.trackingEnv, source: 'web_huffpost', cross_domain_cookies: false }) : () => false;
// set globals to use on other scripts
window.HUFFPOST.content_action = content_action;
window.HUFFPOST.internal_link = internal_link;
window.HUFFPOST.sendClientEvent = sendClientEvent;
window.HUFFPOST.pageview = pageview;

const supportHuffpostImpressionOverride = () => {
  let hasClass = document.body.classList.contains('contributor_canceled');
  if (hasClass) {
    return 'Support HuffPost (Canceled)';
  }
  hasClass = document.body.classList.contains('contributor_once');
  if (hasClass) {
    return 'Support HuffPost (Once)';
  }
  return 'Support HuffPost';
};

window.doImpressions = function (type = 'default', appLayer = {}, specificClass = '') {
  let appOverrideLayer = appLayer;

  // when we call impressions from liveblog the appOverrideLayer would be empty, so try to set it up again if available
  if (hasCetAppsOverride && Object.keys(appOverrideLayer).length === 0 && window.HPApp && type === 'liveblog') {
    appOverrideLayer = {
      client_uuid: window.HPApp?.clientId,
      client_session_id: window.HPApp?.sessionId,
      referrer_uri: window.HPApp?.referrerUri,
    };
  }

  const selectors = {
    default: {
      impressionSelector: '.js-card[data-vars-type], .js-entry-link[data-vars-type], .js-button[data-vars-type]',
      subbuzzImpressionSelector: '.js-cet-subbuzz',
    },
    liveblog: {
      impressionSelector: '.liveblog .js-card[data-vars-type], .liveblog .js-entry-link[data-vars-type], .liveblog button[data-vars-type].liveblog-load-more',
      subbuzzImpressionSelector: '.liveblog .js-cet-subbuzz',
    },
    liveblogExtraContent: {
      impressionSelector: `.liveblog ${specificClass} .js-card[data-vars-type], .liveblog ${specificClass} .js-entry-link[data-vars-type]`,
      subbuzzImpressionSelector: `${specificClass} .js-cet-subbuzz`,
    },
    recirc: {
      impressionSelector: `${specificClass} .js-card[data-vars-type], ${specificClass} .js-entry-link[data-vars-type]`,
    },
    alertBanner: {
      impressionSelector: '.alert-banner a[data-vars-type]',
    },
    notificationCenter: {
      impressionSelector: '.notification-center .js-card[data-vars-type]',
    },
    huffPal: {
      impressionSelector: `${specificClass} .js-cet-impression[data-vars-type]`,
    },
    badgeCards: {
      impressionSelector: '.js-card[data-vars-type].js-can-badge',
    },
  };

  const impressionSelector = selectors[type] ? selectors[type].impressionSelector : selectors.default.impressionSelector;
  const subbuzzImpressionSelector = selectors[type] ? selectors[type].subbuzzImpressionSelector : selectors.default.subbuzzImpressionSelector;

  const pageId = (window.HUFFPOST.tracking && window.HUFFPOST.tracking.context_page_id) || '';
  const pageType = (window.HUFFPOST.tracking && window.HUFFPOST.tracking.context_page_type) || '';

  const enableCET = !document.body.classList.contains('cet-events-disabled') && pageId && pageType;

  // for cards that can have a badge we will attach the impression handler after the badge check passes in frontpage.js
  const allImpressionSelector = '.js-card[data-vars-type]:not(.js-can-badge), .js-entry-link[data-vars-type], .js-cet-subbuzz, button[data-vars-type], a[data-vars-type]:not(.js-card[data-vars-type] a), .js-button[data-vars-type]';

  if (enableCET) {
    const attachImpressionHandler = createClientImpressionHandler((payload) => {
      let newPayload = payload;
      if (newPayload.item_name_overwritable === 'support-huffpost') {
        newPayload.item_name = supportHuffpostImpressionOverride();
        delete newPayload.item_name_overwritable;
      }
      newPayload = Object.assign(newPayload, appOverrideLayer);
      sendClientEvent(impression, newPayload);
    });

    document.querySelectorAll(impressionSelector).forEach((elem) => {
      const payload = mapAmpDataVars(elem);
      if (payload) {
        payload.position_in_unit = positionInUnit(elem, pageType, payload.unit_type, payload.unit_name, allImpressionSelector.split(','));
        Object.assign(
          payload,
          unitDataVars(elem, payload),
          window.HUFFPOST.tracking,
          {
            /* These fields are optional so we can leave them off, but it's likely that
            * when the "deptartment bucket" cards are instrumented, we'll want to
            * specify their values. */
            // data_source_algorithm: '',
            // data_source_algorithm_version: '',
            // data_source_name: '',
            type: 'web_impression',
          },
          appOverrideLayer,
        );
      }
      attachImpressionHandler(elem, payload);
    });

    if (type !== 'recirc') {
      const attachSubbuzzImpressionHandler = createClientImpressionHandler((payload) => {
        Object.assign(payload, appOverrideLayer);
        sendClientEvent(subbuzz_impression, payload);
      });

      document.querySelectorAll(subbuzzImpressionSelector).forEach((elem) => {
        const payload = mapAmpDataVars(elem);
        if (payload) {
          payload.position_in_unit = positionInUnit(elem, pageType, payload.unit_type, payload.unit_name, allImpressionSelector.split(','));
          Object.assign(
            payload,
            unitDataVars(elem, payload),
            window.HUFFPOST.tracking,
            {
              type: 'web_subbuzz_impression',
            },
            appOverrideLayer,
          );
        }
        attachSubbuzzImpressionHandler(elem, payload);
      });
    }
  }
};

const doTheTracking = async (appOverrideLayer = {}) => {
  // TODO: based on feedback from buzzfeed we should refactor code when possible, see details on this ticket: https://buzzfeed.atlassian.net/browse/HUFFPOST-2108

  const trackClientPerformance = (...layers) => {
    trackPerformance(sendClientEvent, { layers: [...layers, appOverrideLayer], sample_rate: 1 });
  };

  /* Gate CET click handlers to the front pages only. This is to prevent modules
     such as FollowUs from generating a click handler when it's present on B pages,
     where it won't have all of the necessary data-vars-* attributes for a successful
     beacon event.

     Update or remove this gate when CET events move past the front page.
   */
  const pageId = (window.HUFFPOST.tracking && window.HUFFPOST.tracking.context_page_id) || '';
  const pageType = (window.HUFFPOST.tracking && window.HUFFPOST.tracking.context_page_type) || '';
  const pageEdition = (window.HUFFPOST.tracking && window.HUFFPOST.tracking.page_edition) || '';

  const pageData = {
    context_page_id: pageId,
    context_page_type: pageType,
    destination: 'huffpost',
    page_edition: pageEdition,
  };

  const enableCET = !document.body.classList.contains('cet-events-disabled') && pageId && pageType;

  if (enableCET) {
    const attachHandler = createClientClickHandler(({ event }) => {
      let target = event.target.closest('a[data-vars-type], button[data-vars-type], div[role="button"][data-vars-type], .js-cet-click[data-vars-type]');
      // for links inside a card use the card to calc position in unit
      const cardTarget = target ? target.closest('.js-card[data-vars-type]') : null;
      target = cardTarget || target;
      const payload = mapAmpDataVars(target);
      if (payload) {
        // position in unit is not known at render time because the modules don't
        // know of their relationship to other modules
        payload.position_in_unit = positionInUnit(target, pageType, payload.unit_type, payload.unit_name);
        Object.assign(payload, unitDataVars(target, payload), window.HUFFPOST.tracking, appOverrideLayer);
        let eventType;
        switch (payload.type) {
          case 'web_content_action':
            eventType = content_action;
            break;
          case 'web_internal_link':
            eventType = internal_link;
            break;
          case 'web_external_link':
            eventType = external_link;
            break;
          default:
            eventType = internal_link;
        }
        sendClientEvent(eventType, payload);
      }
    });

    const attachVideoHandler = createClientClickHandler(({ event }) => {
      const zone = event.target.closest('.vdb_player');
      if (!zone) return null;
      const zonePayload = Object.assign(mapAmpDataVars(zone), window.HUFFPOST.tracking);
      const carouselPayload = findVidibleCarouselDataVars(event.target);
      if (carouselPayload) {
        Object.assign(carouselPayload, zonePayload, appOverrideLayer);
        carouselPayload.position_in_unit = positionInUnit(zone, pageType, zonePayload.unit_type, zonePayload.unit_name);
        return sendClientEvent(internal_link, carouselPayload);
      }
      return null;
    });

    attachHandler(document.body);
    const videoZone = document.getElementById('zone-video');
    if (videoZone) {
      attachVideoHandler(videoZone);
    }

    window.doImpressions('default', appOverrideLayer);
    if (!window.HUFFPOST.pvDisabled) {
      trackClientPerformance(pageData);
    }
  }

  const createTimeSpentClientEvent = (event_config) => (...layers) => {
    sendClientEvent(event_config, pageData, ...layers, appOverrideLayer);
  };

  const trackClientTimeSpent = createTimeSpentClientEvent(time_spent);

  const attachClientTimeSpentHandler = createClientTimeSpentHandler(({ layers }, ...additional_args) => {
    trackClientTimeSpent(...additional_args, ...layers);
  });

  // const comscorePV = () => {
  //   if (window && window.COMSCORE && window.comscore_data) {
  //     window.COMSCORE.beacon(window.comscore_data);
  //   } else {
  //     // eslint-disable-next-line no-console
  //     console.error('could not fire comscore pv');
  //   }
  // };

  const cetPV = (payload) => {
    sendClientEvent(pageview, { ...window.HUFFPOST.tracking, ...payload, ...appOverrideLayer });
  };

  const sendAddressability = (isLoggedIn) => {
    const hem = cookies.get('hem');
    const queryObject = urlQueryToObject(window.location.search);
    const isAddressable = session.get('addressableSource') || false;
    const addressableSource = [];
    if (queryObject?.email_hash) {
      session.set('addressableSource', 'newsletter');
      addressableSource.push('newsletter');
    } else if (isAddressable) {
      session.set('addressableSource', isAddressable);
      addressableSource.push(isAddressable);
    }
    if (isLoggedIn) {
      addressableSource.push('auth');
    }
    const addresableData = {
      is_addressable: !!hem,
      addressable_source: addressableSource,
      addressable_partner: [],
    };
    sendClientEvent(addressability, {
      ...window.HUFFPOST.tracking, ...pageData, ...appOverrideLayer, ...addresableData,
    });
  };

  const sendSubscriptionDetails = (profile) => {
    if (!profile) return;
    const tierEndDate = profile?.membership?.tierEndDate || null;
    let isSubscribed = 'false';
    if (profile?.membership?.hasMembership) {
      isSubscribed = new Date(tierEndDate) > new Date() ? 'true_active' : 'true_cancelled';
    }
    const subscriptionMetadata = new URLSearchParams({
      ad_free: isAdsFree ? 'true' : 'false',
      membership_type: profile?.membership?.tier || '',
      is_active: profile?.membership?.hasMembership && new Date(tierEndDate) > new Date() ? 'true' : 'false',
      id: profile?.membership?.memberId || '',
      huffpost_user_id: profile?.bf_id || '',
      flippay_id: profile?.membership?.fpCustomerId || '',
      is_voluntary_onceoff_contributor: profile?.roles && profile?.roles.includes('contributor_once') ? 'true' : 'false',
    }).toString();

    const subscriptionDetailsData = {
      is_subscribed: isSubscribed,
      subscription_metadata: subscriptionMetadata,
    };
    sendClientEvent(subscription_details, {
      ...window.HUFFPOST.tracking, ...pageData, ...appOverrideLayer, ...subscriptionDetailsData,
    });
  };

  // Send initial page view (this doesn't block)
  if (window.HUFFPOST.tracking) {
    // Wait to get user profile and detect adBlock before firing the first page view
    let isLoggedIn = false;
    const [profile, hasAdBlock] = await Promise.all([getProfile(), detectAdBlock()]);
    if (profile) {
      isLoggedIn = true;
      window.HUFFPOST.tracking.random_user_uuid = 'authenticated';
    }

    if (!window.HUFFPOST.pvDisabled) {
      const payload = {};
      const url = new URL(window.location.href);
      if (isAdsFree) {
        url.searchParams.set('ad_free', 'true');
      }
      if (hasAdBlock) {
        url.searchParams.set('ad_blocker', 'true');
      }
      payload.event_uri = url.href;
      cetPV(payload);
      sendSubscriptionDetails(profile);
      sendAddressability(isLoggedIn);
      attachClientTimeSpentHandler();
    }

    // const googleSiteTagPV = (payload) => {
    //   if (window.gtag) {
    //     window.gtag('event', 'page_view', Object.assign({}, payload));
    //   }
    // };

    document.addEventListener('fireCommentsPV', () => {
      cetPV({ context_page_type: 'buzz_comments' });
      // googleSiteTagPV({ pageType: 'content_comments' });
    });
    if (!window.HUFFPOST.pvDisabled) {
      document.addEventListener('abeagleEvent', (e) => {
        sendClientEvent(ab_test, { ...window.HUFFPOST.tracking, ...e.detail, ...appOverrideLayer });
      }, { once: true });
    }
    // There are, as of now, no "read more" buttons on any Cambria pages
    document.addEventListener('fireReadMorePV', () => {
      cetPV({ context_page_type: 'buzz_read_more' });
      // googleSiteTagPV({ pageType: 'content_read_more' });
    });

    // facebook
    const affiliateLinks = document.querySelectorAll('[data-affiliate]');
    affiliateLinks.forEach((link) => {
      const section = window.HUFFPOST.entry.sectionSlug;
      link.addEventListener('click', () => {
        const data = { section, contents: link.getAttribute('href') };
        // eslint-disable-next-line no-undef
        fbq('track', 'AddToCart', data);
      });
    });
  }
};

if (hasCetAppsOverride) {
  window.waitForGlobalCambria(
    () => window.HPApp && window.HPApp.clientId && window.HPApp.sessionId && (window.HPApp.referrerUri || window.HPApp.referrerUri === ''),
    () => {
      const appLayer = {
        client_uuid: window.HPApp?.clientId,
        client_session_id: window.HPApp?.sessionId,
        referrer_uri: window.HPApp?.referrerUri,
      };
      doTheTracking(appLayer);
    },
    50, // poll every 50ms
    { fallback: 3000, caller: 'cetProxy', fallbackCb: doTheTracking }, // suspend wait after 3 seconds
  );
} else {
  doTheTracking();
}
